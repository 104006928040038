@tailwind base;
@tailwind components;
@tailwind utilities;

img {display:inline-block;}
.hfont {font-family: 'Bricolage Grotesque', sans-serif;}
.box {display: block;}
.flxd { display: flex; justify-content: space-between;}
.flx { display: flex; text-align: right;}
@media only screen and (max-width:566px) { 
	.proj-icon {
        display: none;
    }
}